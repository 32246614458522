import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { SettingsService } from '../settings/settings.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardWithForcedLogin implements CanActivate {
  private isAuthenticated: boolean;
  private role: string;
  private isFormBuilder:boolean;
  constructor(
    private authService: AuthService,
    private settings: SettingsService,
    private router: Router
  ) {
    this.authService.isAuthenticated$.subscribe(i => this.isAuthenticated = i);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    this.role = this.settings.getRole();
    //if this.role is array
     for(let role of this.role)
     {
       if (role === "userFormBuilder") this.isFormBuilder = true;
     }
     //if this.role isn't array
     if (this.role === "userFormBuilder") this.isFormBuilder = true;

    return this.authService.isDoneLoading$
      .pipe(filter(isDone => isDone))
      .pipe(tap(_ => this.isAuthenticated || this.authService.login(state.url)))
      .pipe(map(_ => {if (this.isAuthenticated && this.isFormBuilder) {
        return true;
      }
      this.router.navigate(['/not-found']);
      return false;
    }));
}
}