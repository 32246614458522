// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


//configurations of endpoints for localhost, local and production

export const environment = {
  production: false,
  GoogleClientId: "google",
  FacebookClientId: "facebook",

  //localhost:

/*
  clientId: "formBuilder", //localhost
  UrlFormView:"http://localhost:4100/",
  Uri: "http://localhost:4200",
  ApiFormBuilder:"http://api.formbuilder.dev.prochild.local/Api/Private/",
 //ApiFormBuilder:"http://localhost:5002/Private/",
  //ApiFormBuilder:"https://api.formbuilderv2.prochildcolab.pt/Private/",


   */


    //local
/*
  UrlFormView:"http://formview.prochild.local",
  Uri: "http://formbuilder.dev.prochild.local",
  ApiFormBuilder:"http://api.formbuilder.dev.prochild.local/Api/Private/",
  clientId: "formBuilder-interno",

 */





  //externo v1

  /*
  UrlFormView:"http://formview.prochildcolab.pt",
  Uri: "https://formbuilder.prochildcolab.pt",
  ApiFormBuilder:"https://api.formbuilder.prochildcolab.pt/api/",

   */

  //externo v2


  UrlFormView:"https://prochildforms.prochildcolab.pt",
  Uri: "https://formbuilderv2.prochildcolab.pt",
  ApiFormBuilder:"https://api.formbuilderv2.prochildcolab.pt/Api/Private/",
  clientId: "formBuilderv2-externo", //externo
   /**/


  //SSO- externo

  AuthorityUri: "https://sso.prochildcolab.pt", //sso
  ResourceServer: "https://api.sso.prochildcolab.pt/", //api



  RequireHttps: false,



  RecaptchaV3: ""
};
