<app-header [fixed]="true"
    [navbarBrandFull]="{src: 'assets/brand/logo-82px.png', height: 55}"
    [navbarBrandMinimized]="{src: 'assets/brand/logo-82px.png', height: 55, alt: 'Prochild Project', href: '/project/list'}"
    [asideMenuToggler]="false" [mobileAsideMenuToggler]="false">
    <div class="btHome justify-content-center">
        <a href="/project/list"><img class="imgHome" src="assets/img/home.png" alt="project"></a>
    </div>
    <ul class="nav navbar-nav ml-auto">
        <!-- <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
                dropdownToggle (click)="false">
                <i class="fa fa-bell fa-2x" aria-hidden="true"></i></a>
        </li> -->
        <!-- <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
                dropdownToggle (click)="false">
                <i class="fa fa-2x fa-globe"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right pointer" *dropdownMenu aria-labelledby="simple-dropdown">
                <div class="dropdown-header text-center">
                    <strong>{{ "general.AVAILABLE-LANGUAGES" | translate }}</strong></div>
                <a class="dropdown-item" (click)="setLanguage('pt')"><i class="flag-icon flag-icon-pt"></i>
                    Português</a>
                <a class="dropdown-item" (click)="setLanguage('en')"><i class="flag-icon flag-icon-us"></i>
                    English</a>
                <a (click)="setLanguage('es')" class="dropdown-item"><i class="flag-icon flag-icon-es"></i>
                    Spanish</a>
                <a (click)="setLanguage('fr')" class="dropdown-item"><i class="flag-icon flag-icon-fr"></i>
                    French</a>
            </div>
        </li> -->
        <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link" data-toggle="dropdown" *ngIf="userProfile$ | async; let userProfile" href="#"
                role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
                <i class="fa fa-user fa-2x "></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right pointer" *dropdownMenu aria-labelledby="simple-dropdown">
                <div class="dropdown-header text-center"><strong>Account</strong></div>
                <a class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i> Logout</a>
            </div>
        </li>
        <!-- <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
                dropdownToggle (click)="false">
                <i class="fa fa-question fa-2x ml-2 mr-4" aria-hidden="true"></i>
            </a>
        </li> -->


        <!-- <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link" data-toggle="dropdown" *ngIf="userProfile$ | async; let userProfile" href="#"
                role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
                <img *ngIf="userProfile.picture" class="img-avatar" [src]="userProfile.picture"
                    alt="userProfile.name" />
                <img *ngIf="!userProfile.picture" class="img-avatar" src="/assets/img/dummy.png"
                    alt="userProfile.name" />{{userProfile.name}}&nbsp;&nbsp;
            </a>
            <div class="dropdown-menu dropdown-menu-right pointer" *dropdownMenu aria-labelledby="simple-dropdown">
                <div class="dropdown-header text-center"><strong>Account</strong></div>
                <a class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i> Logout</a>
            </div>
        </li> -->
    </ul>

    <div class="yellowcircle">
    </div>
</app-header>
<div class="app-body aside-menu-hidden">
    <app-sidebar [fixed]="true" [display]="'lg'">
        <app-sidebar-nav [perfectScrollbar] [navItems]="navItems"  [disabled]="sidebarMinimized" id="btnMinimize" ></app-sidebar-nav>
        <app-sidebar-minimizer></app-sidebar-minimizer>
    </app-sidebar>
    <!-- Main content -->
    <main class="main">
        <!-- Breadcrumb -->
        <!-- <ol class="breadcrumb">
            <app-breadcrumb></app-breadcrumb>
        </ol> -->
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
        <!-- /.container-fluid -->
    </main>
</div>
<app-footer>
    <div class="row rFooter d-flex justify-content-center">
        <div class="col-12 text-center">
            <span>&#169; ProChild CoLAB, 2021</span>
        </div>
        <!-- <div class="col-xl-2">
            <span>Prochild Forms &copy; 2020 - version
                {{ settingsService.app.version }}</span>
        </div> -->
    </div>

    <!-- <span class="ml-auto">Powered by <a href="https://coreui.io/angular">CoreUI</a></span> -->
</app-footer>