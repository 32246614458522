<div class="container" style="height: 900px;
width: 1000px;">
    <div class=" row Rregister flex justify-content-center">
        <div class="col-xl-2 circleBlue">

        </div>
        <div class="col-xl-8">
            <div class="row flex justify-content-center" style="padding-top: 105px;">
                <img src="/assets/brand/logo.png" alt="Logo Prochild">
            </div>
            <div class="row Rr" style="padding-top: 100px;">
                <div class="col" style="margin-right: 25px;">
                    <h2>{{ 'register.REGISTER'}}</h2>
                    <p class="bluep">Crie aqui a sua conta.</p>
                </div>
                <div class="col" style="margin-left: 25px;">
                    <h2>estado: </h2>{{registerForm.status}}
                    <form>
                        <div class="row">
                            <div class="col-12">
                                <label class="bluep ml-3">Name</label>
                                <br>
                                <app-input-validation [control]="registerForm.controls.name"
                                    ></app-input-validation>
                                <input type="text"
                                    [ngClass]="{'ng-invalid': registerForm.controls.name.status==='INVALID'}"
                                    placeholder="Name" name="name" autocomplete="cc-name"
                                    [formControl]="registerForm.controls.name" required>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <label class="bluep ml-3">Username</label>
                                <br>
                                <app-input-validation [control]="registerForm.controls.username"
                                    ></app-input-validation>
                                <input type="text" class="inputLblue iconInput greyB noBorder" placeholder="Username"
                                    name="username" autocomplete="username"
                                    [formControl]="registerForm.controls.username" required>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <label class="bluep ml-3">Email</label>
                                <br>
                                <app-input-validation [control]="registerForm.controls.email"
                                    ></app-input-validation>
                                <input type="text" class="inputLblue iconInput greyB noBorder" placeholder="Email"
                                    name="email" autocomplete="email" [formControl]="registerForm.controls.email"
                                    required>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <label class="bluep ml-3">Phone Number</label>
                                <br>
                                <app-input-validation [control]="registerForm.controls.phoneNumber"
                                    ></app-input-validation>
                                <input type="text" class="inputLblue iconInput greyB noBorder"
                                    placeholder="Phone Number" [validation]="false" mask="(000) 000-0000}"
                                    name="phoneNumber" autocomplete="tel"
                                    [formControl]="registerForm.controls.phoneNumber">
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <label class="bluep ml-3">Password</label>
                                <br>
                                <app-input-validation [control]="registerForm.controls.password"
                                    ></app-input-validation>
                                <input type="password" class="inputLblue iconInput greyB noBorder"
                                    placeholder="Password" name="password" autocomplete="new-password"
                                    [formControl]="registerForm.controls.password" required>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-12">
                                <label class="bluep ml-3">Confirme Password</label>
                                <br>
                                <app-input-validation [control]="registerForm.controls.confirmPassword"
                                    ></app-input-validation>
                                <input type="password" class="inputLblue iconInput greyB noBorder"
                                    placeholder="Repeat password" name="confirmPassword" autocomplete="new-password"
                                    [formControl]="registerForm.controls.confirmPassword" required>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <button type="button" class="solidBtnIcon ml-3 mt-5" style="width: 232px;"
                                (click)="register()" *ngIf="!showButtonLoading"> <i class="fa fa-chevron-circle-down fa-lg mr-2"></i>
                                <ng-container *ngIf="!showButtonLoading">{{ 'register.CREATE-ACCOUNT'  }}
                                </ng-container>
                                <app-loading-spinner *ngIf="showButtonLoading" [whiteStroke]="true" [width]="20"
                                    [height]="20"></app-loading-spinner>
                            </button>
                        </div>

                        <div *ngIf="useCaptcha" class="mb-4 text-center">
                            <re-captcha [(ngModel)]="token" name="captcha" required [siteKey]="siteKey"
                                #captchaControl="ngModel"></re-captcha>
                        </div>

                        <button class="btn btn-block btn-success" type="button" *ngIf="showButtonLoading"><i
                                class="fa fa-spinner fa-spin"></i></button>

                        <button type="button" class="btn btn-block btn-danger mt-2" style="width: 232px;"
                            (click)="back()">
                            <ng-container *ngIf="!showButtonLoading">cancel
                            </ng-container>

                        </button>

                        <hr>

                    </form>
                    <div *ngIf="errors.length > 0" class="alertMsg">
                        <alert type="danger" [dismissible]="dismissible" (onClosed)="onClosed()">
                            <div *ngFor="let error of errors; let i = index"><strong>{{i+1}}</strong>
                                {{error}}</div>
                        </alert>
                    </div>


                </div>
            </div>
        </div>
        <div class="col-xl-2 circlePink">
            <img class="circleYellow" src="/assets/img/semicircleyellow.png" alt="">
        </div>
    </div>
</div>