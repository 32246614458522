<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <div class="card mx-4">
                        <div class="card-body p-4">
                            <form>
                                <h3>Recuperar password</h3>
                                <p class="text-muted">Por favor, insira o seu nome de utilizador ou e-mail.</p>
                                
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="icon-user"></i></span>
                                    </div>
                                    <input type="text" class="form-control" placeholder="Username or E-mail" name="name" autocomplete="email" [(ngModel)]="model.usernameOrEmail" required>
                                </div>
                                <button type="button" class="btn btn-primary" (click)="recover()" *ngIf="!emailSent">Enviar link de redefinição</button>
                                
                                <div *ngIf="emailSent">
                                    <alert type="warning">
                                        <div><strong>Sucesso</strong> Verifique seu e-mail.</div>
                                    </alert>
                                </div>
                                <div *ngIf="showButtonLoading">
                                    <div class="col-md-8 mx-auto">
                                            <p class="text-center"><span>Enviando e-mail.</span></p>
                                        <div class="progress mb-3">
                                            <div class="progress-bar progress-bar-striped bg-primary" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>