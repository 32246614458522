<div class="waitPage">
    <div class="d-flex justify-content-center" style="margin-top: 450px;">
        <img src="/assets/img/coffee.gif">
    </div>
    <div class="d-flex justify-content-center" style="padding-top: 40px;">

        <h2 class="">Estamos quase lá...</h2>


    </div>

    <!-- <div class="row">

                <div class="col-md-8 mx-auto">

                    <div class="card p-4">
                        <div class="card-body">
                            <p class="text-center"><span
                                    class="badge badge-warning ml-0">{{ 'login-callback.LOADING' | translate }}</span>
                            </p>
                            <div class="progress mb-3">

                                <div class="progress-bar progress-bar-striped bg-primary" role="progressbar"
                                    style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
</div>