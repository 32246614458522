<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <div class="card mx-4">
                        <div class="card-body p-4">
                            <form>
                                <h1>Redefinir</h1>
                                <p class="text-muted">Redefinir sua senha</p>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="icon-lock"></i></span>
                                    </div>
                                    <input type="password" class="form-control" placeholder="Password" name="password" autocomplete="new-password" [(ngModel)]="model.password" required>
                                </div>
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="icon-lock"></i></span>
                                    </div>
                                    <input type="password" class="form-control" placeholder="Repeat password" name="confirmPassword" autocomplete="new-password" [(ngModel)]="model.confirmPassword" required>
                                </div>
                                <button type="button" class="btn btn-block btn-success" (click)="reset()" *ngIf="!showButtonLoading">Redefinir</button>
                                <button class="btn btn-block btn-success" type="button" *ngIf="showButtonLoading"><i class="fa fa-spinner fa-spin" ></i></button>
                                <div *ngIf="errors.length > 0">
                                    <br>
                                    <alert type="danger">
                                        <div *ngFor="let error of errors; let i = index"><strong>{{i+1}}</strong> {{error}}</div>
                                    </alert>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>