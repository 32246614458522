<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="card-group">
                        <div class="card p-4">
                            <div class="card-body">
                                <h1>Indisponível</h1>
                                <p class="text-muted">Você não tem acesso a está área.</p>
                                <div class="col-6">
                                    <button type="button" class="btn btn-primary px-4" (click)="login()">{{ 'unauthorized.LOGIN' }}</button>
                                  </div>
                            </div>
                        </div>
                        <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
                            <div class="card-body text-center">
                                <div>
                                    <h2>Entre em contato com o Administrador</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>