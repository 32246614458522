<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <div class="card mx-4">
                        <div class="card-body p-4">
                            <form>
                                <h1>{{ 'confirm-email.E-MAIL-CONFIRMATION' }}</h1>
                                <div *ngIf="showButtonLoading">
                                    <div class="col-md-8 mx-auto">
                                        <p class="text-center"><span>{{ 'confirm-email.VALIDATING-E-MAIL'  }}</span></p>
                                        <div class="progress mb-3">
                                            <div class="progress-bar progress-bar-striped bg-primary" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!showButtonLoading && errors.length == 0">
                                    <alert type="success">
                                        <div><strong>{{ 'confirm-email.SUCCESS' }}</strong>{{ 'confirm-email.THANK-YOU.'  }}</div>
                                    </alert>
                                </div>
                                <div *ngIf="errors.length > 0">
                                        <br>
                                        <alert type="danger">
                                            <div *ngFor="let error of errors; let i = index"><strong>{{i+1}}</strong> {{error}}</div>
                                        </alert>
                                    </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>