import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { environment } from "../environments/environment";
import {Interceptor, HttpsRequestInterceptor} from '../app/shared/services/interceptor.module'
import { NgxLoadingModule } from 'ngx-loading';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

import { AppComponent } from "./app.component";

// Import containers
import { DefaultLayoutComponent } from "./core";

const APP_CONTAINERS = [
    DefaultLayoutComponent
];


import {
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
} from "@coreui/angular";

// Import routing module
import { AppRoutingModule } from "./app.routing";

// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

import { CoreModule } from "./core/core.module";
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        CoreModule.forRoot(),
       
        BrowserAnimationsModule, 
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        NgxLoadingModule.forRoot({}),
        Interceptor
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS
    ],
    providers: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
