export const navItems = [
    // { name: "Welcome", url: "/home", icon: "icon-speedometer", badge: { variant: "info", text: "THANKS" } },
   
    // { name: "Perfil", url: "/user/profile", icon: "icon-user" },

  
    { title: true, name: "Projetos" },
    { name: "Lista", url: "/project/list", icon: "icon-layers" },
    { name: "Criar", url: "/project/new", icon: "icon-plus" },
    { title: true, name: "Visualização de dados" },
    { name: "Ver dados", url: "/dataview/project", icon: "icon-chart" },
    { title: true, name: "Definições utilizador" },
    { name: "Definições", url: "/user/account", icon: "icon-settings" }
    //{ title: true, name: "Linha gráfica" },
    // { name: "Design", url: "/graphicLine", icon: "icon-magic-wand" }
    // {
    //     name: "ProChildCOLAB Project",
    //     url: "",
    //     icon: "icon-cloud-download",
    //     class: "mt-auto",
    //     variant: "success"
    // }

];
